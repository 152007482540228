import { formatDate, formatDateTime } from "../../utils/date";


const getLastVisit = (visits) => {
    if (!Array.isArray(visits) || visits.length === 0) return null;

    // Find the most recent visit by sorting the array based on `createdAt`
    const latestVisit = visits.reduce((latest, current) => {
        return new Date(current.createdAt) > new Date(latest.createdAt) ? current : latest;
    });

    // Format the latest visit date or return as is
    return latestVisit ? latestVisit.createdAt : null;
};

export const getVisitColumns = () => {
    return [
        {
            field: "visits",
            headerName: "Customer Name",
            width: 200,
            renderCell: (params) => params?.row?.memberId?.forenameMember + " " + params?.row?.memberId?.surnameMember
        },
        {
            field: "createdAt",
            headerName: "Date",
            width: 180,
            renderCell: (params) => formatDateTime(params?.row?.createdAt)

        },
        {
            field: "Shoopkeeper",
            headerName: "Served By",
            width: 200,
            renderCell: (params) => params?.row?.servedBy?.firstName + " " + params?.row?.servedBy?.lastName
        },
        {
            field: "visitAmount",
            headerName: "Amount",
            width: 120,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: "overridePurchase",
            headerName: "Override",
            width: 100,
        },
        {
            field: "paymentMethod",
            headerName: "Payment Method",
            align: 'center',
            headerAlign: 'center',
            width: 150,
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 200,
        },

    ];

}

export const getServedColumns = () => {
    return [
        {
            field: "Served By",
            headerName: "Served By",
            width: 200,
            renderCell: (params) => params?.row?.servedBy?.firstName + " " + params?.row?.servedBy?.lastName

        },
        {
            field: "createdAt",
            headerName: "Date",
            width: 180,
            renderCell: (params) => formatDateTime(params?.row?.createdAt)

        },
        {
            field: "visits",
            headerName: "Customer Name",
            width: 200,
            renderCell: (params) => params?.row?.memberId?.forenameMember + " " + params?.row?.memberId?.surnameMember
        },
        {
            field: "visitAmount",
            headerName: "Amount",
            width: 120,
        },
        {
            field: "overridePurchase",
            headerName: "Override",
            width: 100,
        },
        {
            field: "paymentMethod",
            headerName: "Payment Method",
            align: 'center',
            headerAlign: 'center',
            width: 150,
        },
        {
            field: "notes",
            headerName: "Notes",
            width: 200,
        },
    ];

}
