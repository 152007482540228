import { Add } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import { formSubscriptionSchema, YupSubscriptionSchema } from './helper';
import { CREATE_SUBSCRIPTION } from '../../../../../redux/types/shared.types';
import InputField from '../../../../shared/RHF/InputField';
import RHFDatePicker from '../../../../shared/RHF/RHFDatePicker';
import styles from '../../user.module.scss';

const SubscriptionForm = ({ handleClose, userData }) => {

    console.log("userData", userData)
    const dispatch = useDispatch();
    let userSchema = object(YupSubscriptionSchema());

    const { control, handleSubmit, formState: { errors, isDirty }, reset } = useForm(formSubscriptionSchema(userSchema));

    const handleCreateSubscription = (data) => {
        const payload = {
            ...data,
            memberId: userData?._id
        }
        dispatch({ type: CREATE_SUBSCRIPTION, payload });
        handleClose();
    };


    useEffect(() => {
        if (userData?.subscription) {
            if (Object.keys(userData?.subscription)?.length) {
                reset({
                    subscriptionAmount: userData?.subscription?.subscriptionAmount,
                    paidDate: dayjs(userData?.subscription?.paidDate),
                    notes: userData?.subscription?.notes
                });
            }
        }
    }, [userData?.subscription, reset]);

    return (
        <>
            <form className={styles.formContainer} onSubmit={handleSubmit(handleCreateSubscription)}>
                <Grid spacing={3} container>

                    <Grid item xs={6} className={styles.memberHeader}>
                        <Typography variant="subtitle1"><b>Name : </b>{`${userData?.forenameMember} ${userData?.surnameMember}`}</Typography>
                    </Grid>
                    <Grid item xs={6} className={styles.memberHeader}>
                        <Typography variant="subtitle1"><b>Email : </b>{userData?.email}</Typography>
                    </Grid>
                    <Grid item xs={6} className={styles.memberHeader}>
                        <Typography variant="subtitle1"><b>Address : </b>{userData?.address}</Typography>
                    </Grid>
                    <Grid item xs={6} className={styles.memberHeader}>
                        <Typography variant="subtitle1"><b>Post Code : </b>{userData?.postCode}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'subscriptionAmount'} placeHolder={'Amount'} required={true} label={"Amount"} type={'text'} />
                    </Grid>
                    <Grid item xs={6}>
                        <RHFDatePicker
                            control={control}
                            errors={errors}
                            name="paidDate"
                            label="Membership Paid Date *"
                            views={['day']}
                            disableFuture={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputField control={control} errors={errors} name={'notes'} placeHolder={'Notes'} label={"Notes"} type={'text'} />
                    </Grid>
                    <Grid display={'flex'} justifyContent={'flex-end'} item xs={12}>
                        <Button
                            sx={{ p: 1, px: 3 }}
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            disabled={!isDirty}
                            type='submit'
                        >
                            Add Subscription
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default memo(SubscriptionForm);
